<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title
        ><h4 class="font-weight-light">{{current_page}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-stepper v-model="e1" non-linear :key="is_reset">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              {{step_one_title}}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              {{step_two_title}}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" :complete="e1 > 3"> CONFIRMATION</v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="grey lighten-1">
                <step1
                  v-on:data="on_select_table"
                ></step1>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="table_no!='' && name_of_customer!=''&& type_of_order!=''"
                  color="primary"
                  @click="reserve_table()"
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12" color="grey lighten-1">
                <step2
                  :key="menus_data.length>0 || order_items.length>0"
                  v-on:data="on_adding_menu"
                  :menus_order="menus_data"
                  :order_items="order_items"
                  :type_of_order="type_of_order"
                ></step2>
              </v-card>
              <v-card-actions>
                <v-btn color="warning" @click="delete_reserve_table(1)">Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="menus_data.length > 0"
                  color="primary" @click="save_order_menu()">
                  Next
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" color="grey lighten-1">
                <confirmation
                  :key="menus_data.length>0"
                  v-on:data="on_adding_menu"
                  :menus_data="menus_data"
                  :type_of_order="type_of_order"
                ></confirmation>
              </v-card>
              <v-card-actions>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-btn color="warning"
                       @click="e1--">
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="menus_data.length>0&&!saving"
                       @click="delete_reserve_table(0)">
                  Save & Print
                </v-btn>
                <v-progress-circular color="primary" indeterminate
                                     v-if="saving"></v-progress-circular>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import Step1 from '@/views/cashier_interface/order_transaction/components/Step1'
  import Step2 from '@/views/cashier_interface/order_transaction/components/Step2'
  import Confirmation from '@/views/cashier_interface/order_transaction/components/Confirmation'
  import moment from "moment";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      step_one_title: 'SELECT TABLE',
      step_two_title: 'ORDER SECTION',

      is_reset: false,
      e1: 1,
      table_no: '',
      name_of_customer: '',
      type_of_order: '',
      order_reference_no: '',
      order_code: '',
      current_page: '',
      date_and_time: '',

      menus_data: [],
      order_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      Step1,
      Step2,
      Confirmation,
    },
    setup() {
      return {
        confirmation_data: {},
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      e1: function () {
        if (this.e1 > 3) {
          this.reset()
        }
      },
    },
    mounted() {
      this.get_not_yet_done_table_order()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id', 'branch_id', 'branch', 'branch_contact_no', 'branch_address', 'company_name']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('table', ['register_table', 'get_table_not_yet_done_order', 'get_table_not_yet_done_order_not_yet_paid', 'delete_register_table']),
      ...mapActions('table_order', ['register_menu_order_table', 'get_table_not_yet_done_and_have_order']),
      ...mapActions('menu_order', ['get_available_menu_have_amount']),
      get_not_yet_done_table_order() {
        switch (this.$router.currentRoute.name) {
          case 'new-order-dine-in':
            this.current_page = 'ORDER SECTION'
            this.get_table_not_yet_done_order({
              branch_id: this.branch_id,
            })
              .then(response => {
                if (Object.keys(response.data).length > 0) {
                  this.on_select_table(response.data)
                  this.e1 = 2
                  this.get_not_yet_done_table_and_have_already_order()
                }
              })
              .catch(error => {
                console.log(error)
              })
            break
          case 'add-new-order-dine-in':
            this.current_page = 'ADDITIONAL ORDER SECTION'
            break
        }
      },
      get_not_yet_done_table_and_have_already_order() {
        this.get_table_not_yet_done_and_have_order({
          branch_id: this.branch_id,
          table_no: this.table_no,
        })
          .then(response => {
            if (Object.keys(response.data).length > 0) {
              this.menus_data = response.data
              this.e1 = 3
            }
          })
          .catch(error => {
            console.log(error)
          })
        this.get_available_menu_have_amount({
          branch_id: this.branch_id,
          type_of_order: this.type_of_order,
        })
          .then(response => {
            this.order_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.is_reset = true
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          Object.assign(this.$data, initialState())
        }, 100)
      },
      on_select_table(value) {
        this.step_one_title = 'SELECTED TABLE # ' + value.table_no + ' ' + value.name_of_customer + ' - ' + value.type_of_order
        this.step_two_title = 'ORDER TABLE # ' + value.table_no + ' ' + value.name_of_customer + ' - ' + value.type_of_order
        this.table_no = value.table_no
        this.name_of_customer = value.name_of_customer
        this.type_of_order = value.type_of_order
        if (this.e1 === 1) {
          this.get_table_not_yet_done_order_not_yet_paid({
            branch_id: this.branch_id,
            table_no: value.table_no,
          })
            .then(response => {
              if (Object.keys(response.data).length > 0) {
                this.on_select_table(response.data)
                this.e1 = 2
                this.get_not_yet_done_table_and_have_already_order()
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      on_adding_menu(value) {
        this.menus_data = value.menus_data
      },
      reserve_table() {
        this.register_table({
          table_no: this.table_no,
          name_of_customer: this.name_of_customer.toUpperCase(),
          type_of_order: this.type_of_order,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.change_snackbar({
              show: true,
              color: response.status === 200 ? 'success' : 'error',
              text: response.data,
            })
            this.get_not_yet_done_table_order()
          })
          .catch(error => {
            console.log(error)
          })
      },
      reference_no_generator() {
        this.date_and_time = moment().format('YYYY-MM-DD HH:mm:ss')
        var number = '0123456789'
        var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789'
        var charactersLength = characters.length
        var result = ''
        var code = ''
        for (var i = 1; i <= 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
          code += number.charAt(Math.floor(Math.random() * number.length))
        }
        this.order_reference_no = 'YU-' + result
        this.order_code = code
      },
      async delete_reserve_table(value) {
        await this.reference_no_generator()
        this.delete_register_table({
          table_no: this.table_no,
          branch_id: this.branch_id,
          is_delete: value,
          reference_no: this.order_reference_no,
          code: this.order_code,
          date: this.date_and_time,
          cashier_id: this.user_id,
        })
          .then(response => {
            if (response.status === 201) {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'PLEASE PRINT RECEIPT ALWAYS',
              })
              this.print_receipt(response.data.reference_no, response.data.code, response.data.name_of_customer, response.data.type_of_order)
            } else {
              this.change_snackbar({
                show: true,
                color: response.status === 200 ? 'success' : 'error',
                text: response.data,
              })
              if (value === 0 && response.status === 200) {
                this.print_receipt(this.order_reference_no, this.order_code, this.name_of_customer, this.type_of_order)
              } else {
                this.e1--
              }
            }
          })
          .catch(error => {
            console.log(error)
          })

      },
      save_order_menu() {
        this.register_menu_order_table({
          table_no: this.table_no,
          branch_id: this.branch_id,
          type_of_order: this.type_of_order,
          menus_data: JSON.stringify(this.menus_data),
        })
          .then(response => {
            this.change_snackbar({
              show: true,
              color: response.status === 200 ? 'success' : 'error',
              text: response.data,
            })
            this.get_not_yet_done_table_and_have_already_order()
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      date_format(value) {
        return moment(value)
      },
      async print_receipt(ref, code, customer, order) {
        var img_1 = this.company_logo
        var add_1 = this.branch_address
        var con_1 = this.branch_contact_no
        var title_array = []
        var head_array = []
        var data_array = []
        var foot_array = []
        var witdss = [154, 30,]
        var witdssTi = [192]
        title_array.push(
          [
            {image: img_1, width: 192, height: 90, style: 'logo'},
          ]
        )
        title_array.push(
          [
            {text: add_1, style: 'subheader'},
          ]
        )
        title_array.push(
          [
            {
              text: 'Contact #: ' + con_1,
              style: 'subheader',
            },
          ]
        )
        head_array.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {text: 'Table #: ' + this.table_no, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {text: 'Name: ' + customer, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {text: 'Oder Type: ' + order, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {text: 'Ref No.: ' + ref, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {text: 'Code No.: ' + code, alignment: 'left',},
          ]
        )
        head_array.push(
          [
            {
              text: this.date_and_time,
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Order', alignment: 'left'},
            {text: 'Pcs', alignment: 'left'},
          ]
        )
        var gtotal = 0
        this.menus_data.forEach(function (item) {
          var tot = (parseFloat(item.menu.amount) * parseInt(item.quantity))
          gtotal += tot
          data_array.push(
            [
              {text: item.menu.title, alignment: 'left'},
              {text: item.quantity, alignment: 'left'},
            ]
          )
        })
        foot_array.push(
          [
            {text: 'COFFEE SPOT BARISTA COPY', alignment: 'center', pageBreak: "after"},
          ]
        )

        var title_array2 = []
        var head_array2 = []
        var data_array2 = []
        var foot_array2 = []
        var witdss2 = [70, 41.5, 15, 41.5]
        var witdssTi2 = [192]
        var img_2 = this.company_logo
        var add_2 = this.branch_address
        var con_2 = this.branch_contact_no
        title_array2.push(
          [
            {image: img_2, width: 192, height: 90, style: 'logo'},
          ]
        )
        title_array2.push(
          [
            {text: add_2, style: 'subheader'},
          ]
        )
        title_array2.push(
          [
            {
              text: 'Contact # ' + con_2,
              style: 'subheader',
            },
          ]
        )
        head_array2.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array2.push(
          [
            {text: 'Table #: ' + this.table_no, alignment: 'left',},
          ]
        )
        head_array2.push(
          [
            {text: 'Name: ' + customer, alignment: 'left',},
          ]
        )
        head_array2.push(
          [
            {text: 'Order Type: ' + order, alignment: 'left',},
          ]
        )
        head_array2.push(
          [
            {text: 'Ref. No.: ' + ref, alignment: 'left',},
          ]
        )
        head_array2.push(
          [
            {text: 'Code No.: ' + code, alignment: 'left',},
          ]
        )
        head_array2.push(
          [
            {
              text: this.date_and_time,
              alignment: 'left'
            },
          ]
        )
        data_array2.push(
          [
            {text: 'Order', alignment: 'left'},
            {text: 'Amt', alignment: 'left'},
            {text: 'Pcs', alignment: 'left'},
            {text: 'Total', alignment: 'left'},
          ]
        )
        var gtotal2 = 0
        this.menus_data.forEach(function (item) {
          var tot = (parseFloat(item.menu.amount) * parseInt(item.quantity))
          gtotal2 += tot
          data_array2.push(
            [
              {text: item.menu.title, alignment: 'left'},
              {text: item.menu.amount, alignment: 'left'},
              {text: item.quantity, alignment: 'left'},
              {
                text: (tot / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left'
              },
            ]
          )
        })
        data_array2.push(
          [
            {text: 'GRAND TOTAL', alignment: 'left'},
            {text: '', alignment: 'left'},
            {text: '', alignment: 'left'},
            {
              text: (gtotal2 / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left'
            },
          ]
        )
        foot_array2.push(
          [
            {text: 'COFFEE SPOT WAITER COPY', alignment: 'center'},
          ]
        )
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          pageMargins: 1,
          content: [
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              table: {
                widths: witdssTi,
                body: title_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi,
                body: head_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss,
                body: data_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi,
                body: foot_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            ,
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              table: {
                widths: witdssTi2,
                body: title_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi2,
                body: head_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss2,
                body: data_array2,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi2,
                body: foot_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
          ],
          footer: {},
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 7,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 8,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        var win = window.open('', '', "width=1000,height=500");
        pdfMake.createPdf(docDefinition).print({}, win)
        this.reset()
        this.e1++
      },
    },
  }
</script>
